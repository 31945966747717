<template>
  <swipe-list
    ref="list"
    class="card"
    :disabled="!enabled"
    :items="products"
    item-key="id"
    :revealed.sync="revealed"
    @closed="setLastEvent('closed', $event)"
    @leftRevealed="setLastEvent('leftRevealed', $event)"
  >
    <template v-slot="{ item, index, revealLeft, revealRight, close, revealed }">
      <div ref="content" class="card-content bg-cl-main-smoke" @click="closeAll">
        <!-- @click.native="itemClick(item)" -->
        <!-- {{ products }} -->
        <!-- <h2>{{ item.title }}</h2>
        <p><b>id:</b> {{ item.id }} <b>description:</b> {{ item.description }} <b>revealed:</b> {{ revealed || 'flase' }}</p>
        <b>index:</b><span> {{ index }}</span> -->
        <product-mobile :product="item" class="card-product" />
      </div>
    </template>
    <template v-slot:right="{ item }">
      <product-action :product="item" :action="'remove'" />
      <product-action :product="item" :action="'add'" @closeSlots="closeAll" />
      <product-action :product="item" :action="'delete'" @closeSlots="closeAll" />
    </template>
    <template v-slot:empty>
      <div>
        list is empty ( filtered or just empty )
      </div>
    </template>
  </swipe-list>
</template>

<script>
import { SwipeList } from 'vue-swipe-actions'
// import 'vue-swipe-actions/dist/vue-swipe-actions.css'
import ProductMobile from 'theme/components/core/blocks/Microcart/ProductMobile'
import ProductAction from 'theme/components/core/blocks/Microcart/ProductAction'

export default {
  components: {
    SwipeList,
    ProductMobile,
    ProductAction
  },
  props: {
    products: {
      type: Array,
      default: null
    }
  },
  data () {
    return {
      enabled: true,
      page: 0,
      revealed: {},
      lastEventDescription: '',
      mockSwipeList: [
        [
          {
            id: 'a',
            title: 'Some title',
            description: 'some description'
          },
          {
            id: 'b',
            title: 'Some title',
            description: 'some description'
          },
          {
            id: 'c',
            title: 'Some title',
            description: 'some description'
          }
        ],
        [
          {
            id: 'd',
            title: 'Some title',
            description: 'some description'
          },
          {
            id: 'e',
            title: 'Some title',
            description: 'some description'
          },
          {
            id: 'f',
            title: 'Some title',
            description: 'some description'
          }
        ]
      ]
    }
  },
  // mounted () {
  //   // ideally should be in some global handler/store
  //   window.addEventListener('keydown', this.onKeyDown)
  //   window.addEventListener('keyup', this.onKeyUp)
  // },
  // beforeDestroy () {
  //   window.removeEventListener('keydown', this.onKeyDown)
  //   window.removeEventListener('keyup', this.onKeyUp)
  // },
  methods: {
    // revealFirstRight () {
    //   this.$refs.list.revealRight(0)
    // },
    // revealFirstLeft () {
    //   this.$refs.list.revealLeft(0)
    // },
    // closeFirst () {
    //   this.$refs.list.closeActions(0)
    // },
    removeItem () {
      this.$refs.removeItem()
    },
    closeAll () {
      this.$refs.list.closeActions()
    },
    // remove (item) {
    //   this.mockSwipeList = this.mockSwipeList.filter(i => i !== item)
    //   // console.log(e, 'remove');
    // },
    setLastEvent (name, { item, index }) {
      this.lastEventDescription = {
        name,
        index,
        id: item.id
      }
    },
    itemClick (e) {
      console.log(e, 'item click')
    },
    fbClick (e) {
      console.log(e, 'First Button Click')
    },
    sbClick (e) {
      console.log(e, 'Second Button Click')
    }
    // onKeyDown (e) {
    //   if (e.keyCode !== 16) return
    //   this.enabled = false
    // },
    // onKeyUp (e) {
    //   if (e.keyCode !== 16) return
    //   this.enabled = true
    // }
  }
}
</script>

<style lang="scss">
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");

.swipeout {
  position: relative;
  overflow: hidden;
  display: flex;
}

.swipeout .swipeout-left,
.swipeout .swipeout-right {
  position: absolute;
  height: 100%;
  display: flex;
  z-index: 1;
}

.swipeout .swipeout-left {
  left: 0;
  transform: translateX(-100%);
}

.swipeout .swipeout-right {
  right: -1px;
  transform: translateX(100%);
}

.swipeout .swipeout-content,
.swipeout .swipeout-action,
.swipeout .swipeout-box {
  transition: transform .2s;
  will-change: transform;
}

.swipeout.swipeout--no-transition .swipeout-content,
.swipeout.swipeout--no-transition .swipeout-action {
  transition: none !important;
}

.swipeout .swipeout-content {
  width: 100%;
}

.swipeout-non-selectable {
  user-select: none !important;
}

.swipeout-no-pointer-events {
  pointer-events: none !important;
}

/* SwipeList */

.swipeout-list {
  display: flex;
  flex-direction: column;
  -ms-flex-direction: column;
}

.swipeout-list-item {
  flex: 1;
  border-bottom: 1px solid #D2D2D2;
}

/* app specific styles */
.swipeout-action {
  display: flex;
  align-items: center;
  padding: 0 1.4rem;
  cursor: pointer;
  left: 0;
}

.swipeout-action.red {
  color: white;
  background-color: rgb(255, 59, 48);
}

.swipeout-action.red:hover {
  background-color: darken(rgb(255, 59, 48), 5%);
}

.swipeout-list-item:last-of-type {
  border-bottom: none;
}

.card {
  width: 100%;
  background-color: white;
  border-radius: 3px;
  box-shadow: none;
  -webkit-box-shadow: none;
}

.card-content {
  // padding: 1rem;
}

.transition-right {
  transform: translate3d(100%, 0, 0) !important;
}

.transition-left {
  transform: translate3d(-100%, 0, 0) !important;
}

.toolbar {
  display: flex;
  align-items: center;
}

.toolbar .toolbar-section {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.toolbar .toolbar-section--center {
  -ms-flex: 1000 1 0%;
  flex: 1000 1 0%;
}
</style>
