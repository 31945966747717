<template>
  <li class="row flex-nowrap py10 px5 m0 relative brdr-bottom-1 brdr-cl-matterhorn">
    <div class="bg-cl-secondary loader-box flex" v-if="loader">
      <loading-spinner />
    </div>
    <div class="w-28 flex image-product">
      <div class="image-box bg-cl-white">
        <img class="image" v-lazy="thumbnail" alt="">
      </div>
    </div>
    <div class="flex px15 start-xs between-sm details w-40 title-product">
      <div>
        <div class="h4 title-font name cl-black weight-700 uppercase">
          {{ product.name | htmlDecode }}
        </div>
        <!-- <div class="h6 cl-bg-tertiary pt5 sku" data-testid="productSku">
          {{ product.sku }}
        </div> -->
        <div class="properties-box">
          <div class="h6 cl-black options" v-if="product.totals && product.totals.options">
            <div v-for="(opt, index) in product.totals.options" :key="opt.label + index">
              {{ $t(opt.label) }}:
              <span class="opv" v-html="opt.value" />
            </div>
          </div>
          <div class="h5 cl-black options" v-else-if="product.options">
            <div v-for="(opt, index) in product.options" :key="opt.label + index" class="my5">
              <span class="opv" v-html="opt.value" />
            </div>
          </div>
          <div class="h6 mt5 cl-error" v-if="product.errors && Object.keys(product.errors).length > 0">
            {{ product.errors | formatProductMessages }}
          </div>
          <div class="h4 pt5 cl-black single-price">
            <span v-if="!bundleMinPrice">{{ product.qty }} x {{ product.price_incl_tax | price(storeView) }}</span>
          </div>
          <p v-show="omnibusPriceMap[product.sku] && isSaleDifference" class="h6 cl-black m0">
            {{ $t(`Najniższa cena w ostatnich 30 dniach: ${omnibusPriceMap[product.sku]} zł`) }}
          </p>
        </div>
        <!-- <div class="h6 pt5 cl-success" v-if="product.info && Object.keys(product.info).length > 0 && Object.keys(product.errors).length === 0">
          {{ product.info | formatProductMessages }}
        </div> -->
      </div>
    </div>
    <div class="flex pl15 align-right start-xs between-sm actions w-32">
      <div class="links" :class="{ 'hidden-xs' : !isEdge }">
        <div class="ff-semi" @click="removeItem">
          <remove-button />
        </div>
      </div>
      <div class="h5 cl-accent lh25 qty flex-center">
        <number-input
          class="ff-light"
          :class="{ 'hidden-xs' : !isEdge }"
          :value="product.qty"
          @click="updateQuantity"
          @blur="updateQuantity"
        />
      </div>
      <div class="prices mt10" v-if="!displayItemDiscounts">
        <span class="h4 h3-sm cl-error price-special" v-if="product.special_price">
          {{ product.price_incl_tax * product.qty | price }}&nbsp;
        </span>
        <span class="h6 price-original" v-if="product.special_price">
          {{ product.original_price_incl_tax * product.qty | price }}
        </span>
        <span class="h4 h3-sm price-regular cl-black" v-if="!product.special_price" data-testid="productPrice">
          {{ product.price_incl_tax * product.qty | price }}
        </span>
      </div>
      <div class="prices mt10" v-if="product.totals && displayItemDiscounts">
        <span
          v-if="product.totals.discount_amount || bundleMinPrice || isSpecialPrice"
          :class="[ omnibusPriceMap[product.sku] && isSaleDifference ? 'cl-error' : 'cl-black' ]"
          class="h4 h3-sm price-special"
        >
          <span v-if="product.totals.discount_amount">{{ product.totals.row_total_incl_tax - product.totals.discount_amount | price(storeView) }}</span>
          <span v-else>{{ product.totals.row_total_incl_tax | price(storeView) }}</span>
        </span>
        <span class="h6 price-original" v-if="
          product.totals.discount_amount ||
            (omnibusPriceMap[product.sku] && isSaleDifference)"
        >
          <span v-if="product.totals.discount_amount">{{ product.totals.row_total_incl_tax | price(storeView) }}</span>
          <span v-else-if="(omnibusPriceMap[product.sku] && isSaleDifference)">{{ (omnibusPriceMap[product.sku] || product.original_price_incl_tax) * product.totals.qty | price(storeView) }}</span>
        </span>
        <span class="h4 h3-sm price-regular cl-black" v-if="!(product.totals.discount_amount || bundleMinPrice || isSpecialPrice)">
          {{ product.totals.row_total_incl_tax | price }}
        </span>
        <!-- <span class="h4 h3-sm price-original cl-secondary" v-if="!product.totals.discount_amount && product.original_price_incl_tax">
          {{ product.original_price_incl_tax | price }}
        </span> -->
      </div>
      <div class="prices mt10" v-else>
        <span class="h4 h3-sm serif price-regular cl-black">
          {{ product.regular_price * product.qty | price }}
        </span>
      </div>
    </div>
  </li>
</template>

<script>
import { mapGetters } from 'vuex'
import LoadingSpinner from 'theme/components/core/LoadingSpinner.vue'
import rootStore from '@vue-storefront/core/store'
import Product from '@vue-storefront/core/compatibility/components/blocks/Microcart/Product'
import RemoveButton from './RemoveButton'
import NumberInput from 'theme/components/core/NumberInput'
import { BrowserDetected } from 'theme/components/core/BrowserDetected.ts'
import { getThumbnailForProduct } from '@vue-storefront/core/modules/cart/helpers'
import { isSpecialPriceActive } from '@vue-storefront/core/modules/catalog/helpers/taxCalc'
import { getBundleOptionsMinPrices } from '@vue-storefront/core/modules/catalog/helpers/bundleOptions'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'

export default {
  components: {
    RemoveButton,
    NumberInput,
    LoadingSpinner
  },
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  mixins: [Product, BrowserDetected],
  data () {
    return {
      displayItemDiscounts: rootStore.state.config.cart.displayItemDiscounts,
      loader: false
    }
  },
  computed: {
    ...mapGetters({
      omnibusPriceMap: 'cart/getOmnibusPriceMap'
    }),
    isSaleDifference () {
      if (!this.salePrice || !this.omnibusPriceMap[this.product.sku]) return false
      if ((this.omnibusPriceMap[this.product.sku] - this.salePrice) <= 0) return false
      return true
    },
    salePrice () {
      let productPrice = 0
      if (this.bundleMinPrice) {
        productPrice = this.product.totals.base_price_incl_tax
      } else {
        productPrice = this.product.price_incl_tax
      }
      return Number(productPrice.toFixed(2))
    },
    storeView () {
      return currentStoreView()
    },
    thumbnail () {
      return getThumbnailForProduct(this.product)
    },
    bundleMinPrice () { // bundle min price
      return this.isSpecialPrice
        ? getBundleOptionsMinPrices(this.product, this.product.price_type).reduce((memo, current) => memo + current, 0)
        : 0
    },
    isSpecialPrice () {
      return this.product.original_special_price && isSpecialPriceActive(this.product.special_from_date, this.product.special_to_date)
    }
  },
  methods: {
    removeFromCart () {
      this.$store.dispatch('cart/removeItem', { product: this.product })
    },
    changeLoader (bool) {
      this.loader = bool
    }
  }
}
</script>

<style lang="scss" scoped>
.loader-box {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  opacity: 0.6;
}
.image-product {
  .image-box {
    display: flex;
    align-items: center;
    border-radius: 9px;
  }
  @media (max-width: 767px) {
    width: 20% !important;
  }
}
.title-product {
  @media (max-width: 767px) {
    width: 60% !important;
    padding-right: 0;
    justify-content: center;
  }
}
 @media (min-width: 768px) {
  .title-product {
    justify-content: center;
  }
  .actions {
    justify-content: space-around;
  }
  .h3-sm {
    font-size: 24px;
  }
 }
.image {
  mix-blend-mode: multiply;
  vertical-align: top;
  // width: 150px;
  height: 150px;
  @media (max-width: 767px) {
    // width: 65px;
    height: 65px
  }
}
.w-40 {
  width: 40%;
}
.w-28 {
  width: 28%;
}
.w-32 {
  width: 32%;
}
.flex-center {
  display: flex;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  align-items: center;
}
  .details {
    flex-direction: column;
    -ms-flex-direction: column;
    @media (max-width: 767px) {
      // padding: 0 10px 0 20px;
    }
  }
  .name {
    height: 38px;
    @media (max-width: 767px) {
      font-size: 12px;
      height: 20px;
      padding-bottom: 10px;
      overflow: hidden;
    }
  }
  .options, .sku {
    @media (max-width: 767px) {
      div {
        margin: 0
      }
      font-size: 10px;
    }
  }
  .qty {
    @media (max-width: 767px) {
      font-size: 12px;
    }
  }
  .actions {
    flex-direction: column;
    -ms-flex-direction: column;
    @media (max-width: 767px) {
      justify-content: flex-end;
      width: 20%;
      padding-left: 0;
      font-size: 12px;
    }
    .links {
      @media (min-width: 768px) {
        display: block;
      }
      @media (max-width: 767px) {
        font-size: 0;
      }
    }
  }
  .price-special {
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
  .price-original {
    text-decoration: line-through;
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
  .price-regular {
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
  input {
    width: 30px;
  }
  .flex-nowrap {
    flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
  }
  .single-price {
    @media (max-width: 767px) {
      font-size: 12px;
      // margin-left: 25px;
    }
  }
  .properties-box {
    @media (max-width: 767px) {
      display: flex;
      flex-direction: column;
      -ms-flex-direction: column;
      // margin-bottom: 10px;
    }
  }
</style>
