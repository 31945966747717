var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('swipe-list',{ref:"list",staticClass:"card",attrs:{"disabled":!_vm.enabled,"items":_vm.products,"item-key":"id","revealed":_vm.revealed},on:{"update:revealed":function($event){_vm.revealed=$event},"closed":function($event){return _vm.setLastEvent('closed', $event)},"leftRevealed":function($event){return _vm.setLastEvent('leftRevealed', $event)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
var index = ref.index;
var revealLeft = ref.revealLeft;
var revealRight = ref.revealRight;
var close = ref.close;
var revealed = ref.revealed;
return [_c('div',{ref:"content",staticClass:"card-content bg-cl-main-smoke",on:{"click":_vm.closeAll}},[_c('product-mobile',{staticClass:"card-product",attrs:{"product":item}})],1)]}},{key:"right",fn:function(ref){
var item = ref.item;
return [_c('product-action',{attrs:{"product":item,"action":'remove'}}),_vm._v(" "),_c('product-action',{attrs:{"product":item,"action":'add'},on:{"closeSlots":_vm.closeAll}}),_vm._v(" "),_c('product-action',{attrs:{"product":item,"action":'delete'},on:{"closeSlots":_vm.closeAll}})]}},{key:"empty",fn:function(){return [_c('div',[_vm._v("\n      list is empty ( filtered or just empty )\n    ")])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }